import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CustomBreakPointsProvider } from 'app/layout/custom-breakpoints';
import { VerticalLayoutModule } from 'app/layout/vertical/vertical-layout.module';
import { HorizontalLayoutModule } from 'app/layout/horizontal/horizontal-layout.module';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FileUploadModule } from "ng2-file-upload";
import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule } from '@angular/common/http';


@NgModule({
  imports: [FlexLayoutModule.withConfig({ disableDefaultBps: true }), VerticalLayoutModule, HorizontalLayoutModule, FileUploadModule, BrowserModule, HttpClientModule],
  providers: [CustomBreakPointsProvider],
  exports: [VerticalLayoutModule, HorizontalLayoutModule, FileUploaderComponent],
  declarations: [
  
    FileUploaderComponent
  ]
})
export class LayoutModule {}
