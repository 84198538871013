import { Component, OnInit } from '@angular/core';
import { BackendApiService } from '../../../services/backend-api.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  selectedFiles: FileList;
  progressInfos = [];
  message = '';
  fileInfos: JSON
  
  constructor (private backendApiService: BackendApiService){
  }

  ngOnInit(): void {
    this.backendApiService.getFiles()
    .subscribe(
      (response) => { this.fileInfos = response; },
      (error) => { console.log(error); });
  } 

  selectFiles(e): void {
    this.progressInfos = [];
    this.selectedFiles = e.target.files;
  }

  uploadSelectedFiles(): void {
    this.message = '';
    for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i]);
    }
  }

  upload(idx, file): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
  
    this.backendApiService.uploadFiles(file).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.backendApiService.getFiles();
        }
      },
      err => {
        this.progressInfos[idx].value = 0;
        this.message = 'Could not upload the file:' + file.name;
      });
  }
}
