import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackendApiService {

  private backendApiUrl = 'https://api.stebbing.de';
  employeeData: JSON;
  fileInfos: JSON;

  constructor(private httpClient: HttpClient) { 
  }

  uploadFiles(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const request = new HttpRequest('POST', `${this.backendApiUrl}/file`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.httpClient.request(request);
  }

  getFiles(): Observable<JSON>  {
     this.httpClient.get(`${this.backendApiUrl}/files`);
     return this.httpClient.get<JSON>(`${this.backendApiUrl}/files`)
   }
}